<template>
	<div class="hero align-items-lg-center">
		<img
			class="hero__logo"
			width="245"
			src="@/assets/logo.png"
			srcset="@/assets/logo@2x.png 2x, @/assets/logo@3x.png 3x"
			alt="pretty love"
		/>

		<div class="wrapper">
			<div class="row">
				<div class="col-12 col-md-9 col-lg-8 col-xl-7">
					<h1 class="hero__title">Let's play, honny bunny?</h1>
					
					<p class="hero__subtitle">Just register and i`ll play with you </p>

					<button type="button" class="hero__btn" @click="$emit('showModal')">Join us</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'hero'
}
</script>

<style lang="scss">
.hero
{
	position: relative;
	display: flex;
	align-items: center;
	align-items: flex-end;
	min-height: 100vh;
	background: #0C0A0D url('~@/assets/hero_bg.jpg') no-repeat right 20% center;
	background-size: cover;
	padding: 50px 0;
	color: #FFF;

	&__logo
	{
		position: absolute;
		top: 20px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;
	}

	&__title
	{
		line-height: 0.9;
		font-size: 5rem;
		letter-spacing: 3px;
	}

	&__subtitle
	{
		margin-top: 25px;
		margin-bottom: 25px;
		line-height: 1.2;
		font-size: 2rem;
		font-family: 'Cormorant Garamond', serif;
		letter-spacing: 6.5px;
	}

	&__btn
	{
		display: inline-block;
		vertical-align: top;
		min-height: 47px;
		padding-bottom: 5px;
		font-size: 3rem;
		font-weight: 600;
		text-decoration: underline;
		cursor: pointer;
		color: #E35B03;
		background: none transparent;
		border: none;
		border-radius: 0;
		box-shadow: none;

		&:hover
		{
			text-decoration: none;
		}
	}

	@media (min-width: 576px)
	{
		
	}

	@media (min-width: 768px)
	{
		&__logo
		{
			width: 320px;
		}

		&__title
		{
			font-size: 9rem;
		}

		&__subtitle
		{
			margin-top: 35px;
			margin-bottom: 35px;
		}
	}

	@media (min-width: 992px)
	{
		
	}

	@media (min-width: 1200px)
	{
		&__logo
		{
			width: auto;
		}

		&__title
		{
			font-size: 13rem;
		}

		&__subtitle
		{
			margin-bottom: 45px;
			font-size: 3rem;
		}
	}
}
</style>
