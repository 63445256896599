<template>
	<div id="app">
		<c-hero @showModal="onToggleModal"/>

		<main role="main">
			<section class="section">
				<div class="wrapper">
					<div class="section-heading">
						<h2 class="section-heading__title">Three easy steps…</h2>
					</div>

					<div class="features">
						<div class="features__inner">
							<div class="row justify-content-center">
								<div v-for="(oFeature, index) in aFeatures" :key="'f' + index"
									class="col-12 col-md-6 col-lg-4"
								>
									<div class="features__item">
										<div class="row">
											<div class="col-12 col-xl-auto">
												<i class="features__ico" :class="'i-' + (index + 1)"></i>
											</div>

											<div class="col-12 col-xl">
												<div class="features__title">{{ oFeature.title }}</div>
												<p>{{ oFeature.content }}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="section section--no-pt">
				<div class="wrapper">
					<div class="section-heading">
						<h2 class="section-heading__title">Profiles</h2>
					</div>

					<div class="profile">
						<nav class="profile__filter">
							<span @click="onToggleModal" class="active">All</span>
							<span @click="onToggleModal">Female</span>
							<span @click="onToggleModal">Male</span>
						</nav>

						<div class="profile__slider" ref="glide">
							<div data-glide-el="track" class="glide__track">
								<div class="glide__slides">
									<div
										v-for="(oProfile, index) in aProfiles"
										:key="'glide__slide' + index"
										class="glide__slide"
									>
										<div class="profile__item"
											@click="onToggleModal"
										>
											<span v-if="isOnline()" class="profile__status">online</span>
											<figure class="profile__img">
												<img :src="applyImgSrc(oProfile.photo)" :alt="oProfile.name + ' ' + oProfile.age" />
											</figure>
											<div class="profile__info">
												<div class="profile__name" v-text="oProfile.name"></div>
												{{ oProfile.age }} years
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="glide__controls" data-glide-el="controls">
								<button class="glide__prev" data-glide-dir="<"></button>
								<button class="glide__next" data-glide-dir=">"></button>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="section section--quiz" :class="{'__end': iCurrStep === 3}">
				<div class="wrapper">
					<div v-if="iCurrStep < 3" class="section-heading section-heading--light">
						<h2 class="section-heading__title">Make your choice</h2>
					</div>

					<div class="quiz">
						<div class="quiz__inner">
							<span v-if="iCurrStep < 3" class="quiz__choose">or</span>

							<div class="row justify-content-center">
								<template v-if="iCurrStep === 1">
									<div class="col-6">
										<div class="quiz__item" @click="onChangeStep">
											<div class="quiz__img img-1"></div>
											Kristin Watson, 25 years
										</div>
									</div>

									<div class="col-6">
										<div class="quiz__item" @click="onChangeStep">
											<div class="quiz__img img-2"></div>
											Jenny Wilson, 26 years
										</div>
									</div>
								</template>

								<template v-else-if="iCurrStep === 2">
									<div class="col-6">
										<div class="quiz__item" @click="onChangeStep">
											<div class="quiz__img img-3"></div>
											Kristin Watson, 25 years
										</div>
									</div>

									<div class="col-6">
										<div class="quiz__item" @click="onChangeStep">
											<div class="quiz__img img-4"></div>
											Jenny Wilson, 26 years
										</div>
									</div>
								</template>

								<template v-else>
									<div class="col-12 col-md-10 col-lg-9 col-xl-6">
										<div class="quiz__title">Good choice</div>
										<div class="quiz__subtitle">we have selected an excellent choice for you</div>
									</div>

									<div class="col-12 col-md-10 col-lg-9 col-xl-6">
										<form-signup />
									</div>
								</template>

								<div class="col-12 col-md-10 col-lg-9 col-xl-12">
									<div class="quiz__step" :data-num="iCurrStep">/3</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>

		<footer class="footer">
			<div class="wrapper">
				<img class="footer__logos img-fluid" src="./assets/footer-logos.png" width="330" height="35" alt="logos" />
				<p>Onlinedating.com {{ (new Date()).getFullYear() }} &copy; All rights reserved</p>
			</div>
		</footer>

		<transition name="fade">
			<div v-if="!bHideModal" class="modal">
				<div class="modal__inner">
					<div class="container">
						<form-signup />
					</div>
				</div>

				<button class="modal__close" @click="onToggleModal" type="button"></button>
			</div>
		</transition>
	</div>
</template>

<script>
import axios from 'axios';

import Glide     from '@glidejs/glide';
import scrolling from '@/utils/scrolling';

import Hero       from '@/components/Hero.vue';
import FormSignup from '@/components/FormSignup.vue';

export default {
	name: 'App',

	components: {
		'c-hero': Hero,
		'form-signup': FormSignup
	},

	data: function ()
	{
		return {
			bHideModal: true,
			iCurrStep: 1,

			aFeatures: Object.freeze([
				{
					title: 'Add profile',
					content: 'Register and get a unique opportunity to view unlimited girls and their profiles'
				},
				{
					title: 'Profiles',
					content: 'On our resource, the most unique profiles of girls, mostly Slavs'
				},
				{
					title: 'Love stories',
					content: 'Several thousand couples have come together thanks to our Pretty love service, join us!'
				}
			]),
			aProfiles: []
		};
	},

	created: async function ()
	{
		this.aProfiles = await this.fetchUsers();

		this.$nextTick(this.init);

		await this.sendPidAndSid();
	},

	mounted: function ()
	{
		var aPreloadImages = [
			require('@/assets/quiz/3.jpg'),
			require('@/assets/quiz/4.jpg'),
			require('@/assets/quiz_bg.jpg')
		];

		setTimeout(preloadImages(aPreloadImages), 5000);
	},

	beforeDestroy: function ()
	{
		this.destroy();
	},

	methods: {
		onChangeStep: function ()
		{
			this.iCurrStep++;
		},

		onToggleModal: function (_value)
		{
			if (this.bHideModal)
			{
				scrolling.hide();
			}
			else
			{
				scrolling.restore();
			}

			this.bHideModal = !this.bHideModal;
		},

		fetchUsers: async function ()
		{
			let options = {
				method: 'post',
				url: '/api/?action=get_profiles'
			};

			try
			{
				var oResp = await axios(options);

				return Object.values(oResp.data).slice(0, 15);
			}
			catch (e)
			{
				console.error(e);

				return e;
			}
		},

		sendPidAndSid: async function ()
		{
			if (process.env.NODE_ENV === 'development') return;

			let params = new URL(document.location).searchParams;
			let data = 'pid=' + params.get("pid") + '&sid=' + params.get("sid") + '&referer=' + document.referrer;

			let options = {
				method: 'post',
				url: '/api/?action=send_partner_info',
				data: data
			};

			try
			{
				await axios(options);
			}
			catch (e)
			{
				console.error(e);

				return e;
			}

			console.log("Success send pid/sid");
		},

		applyImgSrc: function (_sUrl)
		{
			return '//storage.victoriyaclub.com' + _sUrl;
		},

		isOnline: function ()
		{
			return Math.random() < 0.5;
		},

		init: function ()
		{
			if (!this.aProfiles.length) return;


			var option = {
					type: 'carousel',
					perView: 4,
					gap: 30,
					keyboard: false,
					swipeThreshold: 10,
					dragThreshold: 30,
					autoplay: 5000,
					animationDuration: 500,
					animationTimingFunc: 'cubic-bezier(0,0,0.2,1)',
					breakpoints: {
						991: {
							perView: 3
						},
						575: {
							perView: 2,
							gap: 10
						}
					}
				};

			var oSlider = this.slider = new Glide(this.$refs.glide, option);

			oSlider.mount();
		},

		destroy: function ()
		{
			if (this.slider && this.slider.destroy)
			{
				this.slider.destroy();
				this.slider = null;
			}
		}
	}
}

function preloadImages (_aImgs)
{
	var i, L, image;

	for (i = 0, L = _aImgs.length; i < L; i++)
	{
		image = new Image();
		image.src = _aImgs[i];
	}
}
</script>

<style lang="scss">
@import "~@glidejs/glide/dist/css/glide.core.min.css";
@import './styles/style';

.section--quiz
{
	background-color: #0D0B0C;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	&.__end
	{
		
		background-image: url('~@/assets/quiz_bg.jpg');
	}
}

.features
{
	$offset-Y: 50px;

	&__inner
	{
		margin-bottom: $offset-Y * -1;
	}

	&__item
	{
		position: relative;
		margin-bottom: $offset-Y;
		text-align: center;
	}

	&__ico
	{
		display: block;
		margin: auto;
		margin-bottom: 15px;
		width: 85px;
		height: 85px;
		background-color: #FED1B4;
		background-repeat: no-repeat;
		background-position: center;
		border-radius: 20px;

		&.i-1
		{
			background-image: url('~@/assets/features/1.svg');
			background-size: 39px 39px;
		}
		&.i-2
		{
			background-image: url('~@/assets/features/2.svg');
			background-size: 32px 39px;
		}
		&.i-3
		{
			background-image: url('~@/assets/features/3.svg');
			background-size: 39px 35px;
		}
	}

	&__title
	{
		margin-bottom: 5px;
		line-height: 1;
		font-size: 3rem;
		font-weight: 700;
		font-family: 'Cormorant Garamond', serif;
		color: #000;
	}

	@media (min-width: 576px)
	{
		
	}

	@media (min-width: 768px)
	{
		&__ico
		{
			width: 100px;
			height: 100px;

			&.i-1
			{
				background-size: 49px 49px;
			}
			&.i-2
			{
				background-size: 42px 49px;
			}
			&.i-3
			{
				background-size: 49px 45px;
			}
		}
	}

	@media (min-width: 1200px)
	{
		&__item
		{
			text-align: inherit;
		}

		&__ico
		{
			margin-bottom: 0;
		}
	}
}

.profile
{
	$offset-Y: 30px;

	&__filter
	{
		margin-left: -50px;
		margin-bottom: 35px;
		line-height: 1.2;
		font-size: 2rem;
		color: #828282;

		span
		{
			margin-left: 50px;
			cursor: pointer;
			transition: color 300ms ease-in-out;

			&:hover,
			&.active
			{
				text-decoration: underline;
				color: #343740;
			}
		}

		.active
		{
			font-size: 120%;
			font-weight: 600;
		}
	}

	&__inner
	{
		margin-bottom: $offset-Y * -1;
	}

	&__item
	{
		position: relative;
		margin-bottom: $offset-Y;
		font-weight: 600;
		overflow: hidden;
		border-radius: 20px;
		cursor: pointer;

		&::after
		{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(180deg, rgba(21, 21, 21, 0) 73%, #100F0F 100%);
		}

		&:hover
		{
			img
			{
				transform: scale(1.2) translateZ(0);
			}
		}
	}

	&__status
	{
		position: absolute;
		top: 15px;
		right: 15px;
		width: 70px;
		line-height: 25px;
		font-style: normal;
		text-align: center;
		color: #FFF;
		background-color: #22CA55;
		border-radius: 5px;
		z-index: 1;
	}

	&__img
	{
		position: relative;

		&::before
		{
			content: "";
			display: block;
			padding-top: percentage(570/360);
		}

		img
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			@include transition(
				transform 700ms cubic-bezier(0.25, 0.46, 0.45, 0.94)
			);
		}
	}

	&__info
	{
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 20px 20px 20px;
		line-height: 1;
		font-size: 1.4rem;
		color: #FFF;
		z-index: 1;
	}

	&__name
	{
		font-size: 2rem;
	}

	.glide__bullets
	{
		margin-top: 20px;
	}

	@media (min-width: 576px)
	{
		&__filter
		{
			margin-bottom: 50px;
			font-size: 2.5rem;
		}

		&__status
		{
			width: 85px;
			line-height: 30px;
		}

		&__info
		{
			font-size: 1.8rem;
		}

		&__name
		{
			font-size: 2.5rem;
		}
	}

	@media (min-width: 768px)
	{

	}

	@media (min-width: 992px)
	{
		&__status
		{
			top: 25px;
			right: 25px;
		}

		&__info
		{
			font-size: 2rem;
		}

		&__name
		{
			font-size: 3.5rem;
		}
	}

	@media (min-width: 1200px)
	{
		
	}
}

.quiz
{
	position: relative;
	font-weight: 600;
	color: #FFF;

	&__inner
	{

	}

	&__choose
	{
		position: absolute;
		top: 25%;
		left: 50%;
		width: 70px;
		margin-left: -35px;
		line-height: 70px;
		font-size: 2rem;
		text-align: center;
		background-color: #0D0B0C;
		border-radius: 50%;
		z-index: 1;
	}

	&__item
	{
		font-size: 1.6rem;
		font-family: 'Cormorant Garamond', serif;
		text-align: center;
		cursor: pointer;
	}

	&__img
	{
		padding-top: percentage(230/160);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		margin-bottom: 15px;
		border-radius: 20px;

		&.img-1 { background-image: url('~@/assets/quiz/1.jpg'); }
		&.img-2 { background-image: url('~@/assets/quiz/2.jpg'); }
		&.img-3 { background-image: url('~@/assets/quiz/3.jpg'); }
		&.img-4 { background-image: url('~@/assets/quiz/4.jpg'); }
	}

	&__title,
	&__subtitle
	{
		line-height: 0.9;
		font-family: 'Cormorant Garamond', serif;
	}

	&__title
	{
		margin-bottom: 30px;
		font-size: 5rem;
	}

	&__subtitle
	{
		margin-bottom: 40px;
		font-size: 3rem;
	}

	&__step
	{
		margin-top: 10px;
		line-height: 1;
		font-size: 3rem;
		font-family: 'Cormorant Garamond', serif;
		font-weight: 600;
		text-align: right;

		&::before
		{
			content: attr(data-num);
			display: inline-block;
			line-height: 0.5;
			font-size: 7rem;
		}
	}

	@media (min-width: 576px)
	{
		&__choose
		{
			top: 20%;

			width: 100px;
			margin-left: -50px;
			line-height: 100px;

			font-size: 4rem;
		}

		&__item
		{
			font-size: 2.2rem;
		}

		&__img
		{
			margin-bottom: 35px;
			padding-top: percentage(710/750);
		}

		&__title
		{
			font-size: 9rem;
		}

		&__subtitle
		{
			margin-bottom: 70px;
		}
	}

	@media (min-width: 768px)
	{
		&__choose
		{
			top: 20%;

			width: 130px;
			margin-left: -65px;
			line-height: 130px;
		}

		&__item
		{
			font-size: 3rem;
		}
	}

	@media (min-width: 992px)
	{
		&__choose
		{
			top: 24%;

			width: 160px;
			margin-left: -80px;
			line-height: 160px;
		}
	}

	@media (min-width: 1200px)
	{
		&__choose
		{
			top: 30%;
		}

		&__title
		{
			padding-top: 70px;
			font-size: 13rem;
		}

		&__subtitle
		{
			margin-bottom: 0;
		}
	}
}

.footer
{
	padding-top: 40px;
	padding-bottom: 20px;
	line-height: 1.2;
	font-size: 1.2rem;
	color: #828282;
	text-align: center;

	&__logos
	{
		margin-bottom: 20px;
	}

	@media (min-width: 576px)
	{
		padding-top: 55px;
		padding-bottom: 35px;

		&__logos
		{
			margin-bottom: 35px;
		}
	}
}

.glide
{
	&__controls
	{
		font-size: 0;
		text-align: right;
	}

	&__prev,
	&__next
	{
		display: inline-block;
		vertical-align: top;
		width: 42px;
		height: 42px;
		background: #E35B03 url('~@/assets/icon-arrow.svg') 60% center no-repeat;
		background-size: 10px 20px;
		cursor: pointer;
		border: none;
		border-radius: 50%;
		box-shadow: none;
	}

	&__prev
	{
		transform: rotate(180deg);
	}

	&__next
	{
		margin-left: 20px;
	}

	@media (min-width: 576px)
	{
		&__controls
		{
			margin-top: 10px;
			padding-right: 20px;
		}

		&__prev,
		&__next
		{
			width: 60px;
			height: 60px;
			background-size: 15px 30px;
		}

		&__next
		{
			margin-left: 30px;
		}
	}
}


.fade-enter-active,
.fade-leave-active {
	transition: opacity 300ms;
}

.fade-enter,
.fade-leave-to {
	transition: opacity 300ms;
	opacity: 0;
}

.modal
{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	background-color: rgba(#15171b, 0.75);
	overflow: auto;
	z-index: 10;
	backface-visibility: hidden;
	backdrop-filter: blur(10px);

	&::before
	{
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 0;
		height: 100%;
	}

	&__inner
	{
		position: relative;
		display: inline-block;
		vertical-align: middle;
		min-width: 100%;
		padding: 30px 0;
	}

	&__close
	{
		position: absolute;
		top: 20px;
		right: 20px;
		padding: 30px 30px 0 0;
		background: #FFF;
		border: none;
		border-radius: 50%;
		cursor: pointer;
		box-shadow: 0px 18px 28px rgba(9, 30, 66, 0.15);

		&::before,
		&::after
		{
			content: "";
			position: absolute;
			background: #343740;
			transform: rotate(45deg);
		}

		&::before
		{
			width: 2rem;
			height: 4px;
			top: 50%;
			left: 50%;
			margin-top: -2px;
			margin-left: -1rem;
		}

		&::after
		{
			width: 4px;
			height: 2rem;
			left: 50%;
			top: 50%;
			margin-top: -1rem;
			margin-left: -2px;
		}

		@media (min-width: 768px)
		{
			padding: 55px 55px 0 0;

			&::before
			{
				width: 2.8rem;
				margin-left: -1.4rem;
			}

			&::after
			{
				height: 2.8rem;
				margin-top: -1.4rem;
			}
		}
	}
}
</style>
